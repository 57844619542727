.content {
  min-height: 25vh;
  max-height: 75vh;
}
@media (min-width: 640px) {
  .content {
    max-height: fit-content;
  }

  .main-container {
    max-height: fit-content;
  }
}
