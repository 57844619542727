.lds-ripple {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(2, 132, 199);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
.lds-ripple div:nth-child(3) {
  animation-delay: -0.9s;
  /* border: 4px solid red; */
}
@keyframes lds-ripple {
  0% {
    top: 50px;
    left: 50px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 50px;
    left: 50px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 50px;
    left: 50px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}
